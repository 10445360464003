.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
    background:linear-gradient(135.74deg, #0bb5ff 14.09%, #6dd3fe 83.81%);
    text-align: center;
}
.cFooterWrapper>hr{
    width: 100%;
    height: 1px;
    border: none;
    background: white;
    margin-top: 1rem;
}
